import React from 'react';

import Section from '../../../HOC/Section';

const about = () => {
  return (
    <Section id='about'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Who Are We?</span>
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
          <p className="about-text">We have prior experience in training law enforcement working dogs and have now transitioned to training dogs for the general public. We specialize in obedience training, bite/protection work and tracking.</p>
          <p className="about-text">Our goal is to train your dog to his/her fullest abilities so they can enjoy life with you. Providing your dog with basic skills can save you years of headache. </p>
          <p className="about-text">PLEASE be advised that we pick and choose the dogs who we decide to work with regarding protection training. This is a case-by-case situation due to the nature of the training. Not all dogs and/or owners are suitable for protection work. All our training is done 1 on 1, we do NOT have group classes. We are not your typical dog training school. Our results speak for themselves.</p>

          <p className="about-text"><i>We provide services in English, Punjabi, Hindi and Urdu</i></p>
          </h6>
        </div>
      </div>
    </Section>
  );
};

export default about;
