import React, { Fragment } from 'react';

import Home from './Home/Home';
import About from './About/About';
import Service from './Service/Service';
import Contact from './Contact/Contact';
import Media from './Media/Media';

const sections = () => {
  return (
    <Fragment>
      <Home />
      <Media />
      <About />
      <Service />
      {/* <Blog /> */}
      {/* <Facts /> */}
      <Contact />

      {/* <MapMarker /> */}
    </Fragment>
  );
};

export default sections;
