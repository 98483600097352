import React from 'react';

import Section from '../../../HOC/Section';

const contact = () => {
  return (
    <Section id='contact'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Contact Us</span>
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
          <p className='contact-text'>If you have any questions or would like to inquiry about one of our packages, please don't hesitate to reach out.  </p>
          <p className='contact-phone'>Phone: <a href = "tel: 416-875-9453">416-875-9453</a></p>
          <p className='contact-email'>Email: <a href = "mailto: alphadogtrainingbc@outlook.com">alphadogtrainingbc@outlook.com</a></p>
          </h6>
        </div>
      </div>
    </Section>
  );
};

export default contact;
