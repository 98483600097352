import React from 'react';

import Section from '../../../HOC/Section';

const Service = () => {
  return (
    <Section id='services'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Packages</span>
          </h3>
          <p className='section-subtitle'><b>All training is 1 on 1. We do not accomodate group classes.</b></p>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-md-6 col-lg-4 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='fas fa-user' />
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Initial Consultation With Head Trainer & Your Dog <span>$50</span></h5>
                  <h5 className='service-duration'>Duration: 30 mins</h5>
                  <p className='service-description'>
                  This initial consultation session usually lasts approximately 30 minutes. The session is completed in-person and is 1 on 1 with our head trainer. The head trainer will provide you with plan and recommend training strategies / plans to you. This is also a good opportunity to ask any questions or problems you might be encountering with your dog.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='fas fa-dog' />
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Obedience</h5>
                  <h5 className='service-duration'>Duration: 1hr/session </h5>

                  <p className='service-description'>
                   <span>1 Sessions - $150</span>
                   </p>


                  <p className='service-description'>
                   <span>3 Sessions - $330</span>
                   This package includes basic commands such as "come", "sit", "down" and "stay". This package is for anyone looking to train their house pet in basic obedience and/or is having behavioural issues.</p>

                  <p className='service-description'>
                   <span>5 Sessions - $540</span>
                   This is an <b>intermediate</b> obedience package. If you're looking to train have your dog trained OFF LEASH, this package is for you. We will also introduce commands such as "out", "leave it", "place" and "emergency recall".
                   </p>

                   <p className='service-description'>
                   <span>7 Sessions - $750</span>
                    This package is recommended for clients forwarding their dog into protection training. This is <b>advanced</b> obedience. The dog will learn verbal and non-verbal commands (hand signal). This package will give you the biggest bang for your buck. 
                   </p>

                   <p className='service-description'>
                    <b>Aggressive / Reactive dogs (to other animals or dogs)</b> <br/> Additional $30 per session
                   </p>

                   <p className='service-description'>
                    <b>Aggressive / Reactive dogs (to other humans)</b> <br/> Prices vary depending on the dog and the situation. Please contact us for a quote.
                   </p>

                   <p className='service-description'>
                    <b>E-Collar Training</b> <br/> Additional $20 per session
                   </p>
                </div>
              </div>
            </div>
            {/* <div className='col-md-6 col-lg-4 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='fas fa-dog' />
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Dog Walking</h5>
                  <p className='service-description'>
                  
                  <p className='service-description'>
                  All of our dog walkers are also dog trainers. With us, you can rest assure that your dog is walking on a loose leash. We will utilize proper dog walking techniques, so you can have a peace of mind that your dog is not picking up any bad habits while out walking with our dog walkers. All of dog walks are private 1-on-1 walks and on a standard 6 foot leash.
                  <br/><br/>
                  Trainer will walk your dog and will also refresh your dog’s training by utilizing commands such a heel, sit, down, stay and leave it.
                  </p>

                  <p className='service-description'>
                    30 minute walk - $25 <br/>
                    1 hour walk - $40 <br/>
                    1 hour training walk - $55<br/>
                  </p>
                  
                   <span><b>10 Walk Package</b></span>
                    30 minute session - $230 <br/>
                    1 hour session - $380 <br/>
                    Training walks - $540 <br/>
                   </p>

                   <p className='service-description'>
                   <span><b>Full Month Package</b></span>
                    30 minute session - $600 ($20/session) <br/>
                    1 hour session - $1050 ($35/session) <br/>
                    Training walks - $1550 ($50/session) <br/>
                   </p>

                   <i>Cancellations - please advise us 24 hours prior to the session, if you would like to cancel the session / walk. Failure to do will result in the session being charged</i>
                </div>  
              </div>
            </div>*/}
            <div className='col-md-6 col-lg-4 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='fas fa-shield-alt' />
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Protection</h5>
                  <p className='service-description'>
                   <span>Level 1 (5 weeks) - $1500</span>
                   If your dog has basic obedience and you think it is ready to step in the "protection" world, this is the right package for you. We will train your dog in any area of obedience that he/she is lacking and introduce <b>bite work</b>, <b>prey drive</b> and <b>alert</b> (barking at the bad guy on command) training to them.
                   </p>
                   <p className='service-description'>
                   <span>Level 2 (5-6 months) - $7000</span>
                   Want your family to be protected? Do you want a peace of mind that your business is secured when you are not there? Want a dog that will stop the bad guy in his tracks? This package is for you. This is <b>ADVANCED</b> protection. We will come to you and train your dog, where YOU desire (home, jewelry store, auto yard etc). Imagine taking your dog anywhere you want off-leash, having the dog around your family and friends but also being able to give a command that will turn your dog into a "fur-missile" or a "land shark", if required. We will make sure your dog is family friendly but also a protector of your family. We guarantee your dog will be the one standing at the end of the fight.                    </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='fas fa-school' />
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Board & Train</h5>
                  <p className='service-description'>
                   <span>Level 1 (2 weeks) - $2500</span>
                   </p>
                   <p className='service-description'>
                   <span>Level 2 (4 weeks) - $4400</span>
                   </p>
                   <p className='service-description'>
                   Board and train is a perfect program if you do not have the time to train your dog but want the results.
                   </p>
                   <p className='service-description'>
                   We pick your dog up from your residence, provide care and training in whichever training method you desire. It should be noted that the dog your looking to board should have crate trained and should be Ok sleeping in a crate overnight. </p>
                
                   <p className='service-description'> We take pride in training the dogs properly, hence we want to focus all of our efforts on one dog and give it our 110% effort. Therefore, it should be noted we only board and train one dog at a time. </p>

                    <p className='service-description'>It should also be noted that the dogs will be training approximately 4-5 times a day in short durations. This method has shown to provide the best results. </p>
                
                    <p className='service-description'>This is a perfect choice for when you go on vacation. Leave your dog with a trusted trainer, while they learn some new skills!</p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-4 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='fas fa-paw' />
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Tracking <span>$1500</span></h5>
                  <h5 className='service-duration'>Duration: 5 weeks</h5>
                  <p className='service-description'>
                  Teach your dog to track. This training is used for competition tracking but it also has numerous real-life benefits. Locating missing persons, lost children in the woods/campgrounds, search and rescue etc. Call us and let us help.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Service;
