import React from 'react';
import Section from '../../../HOC/Section';
import Instagram from '../../../assets/img/instagram.svg';
import TikTok from '../../../assets/img/tiktok.svg';

const media = () => {
  return (
    <Section id='media'>
      <div className='container'>
        <div className='section-header pt-4 pb-3 text-center'>
          <h3 className='section-title'>
            <span>Follow Us On</span>
          </h3>
          <a href="https://www.tiktok.com/@alpha_dog_training_bc" target="_blank" rel="noopener noreferrer">
            <img className="media-icon" src={TikTok} alt="TikTok"/>
          </a>
          <a href="https://www.instagram.com/alpha.dog.training.bc/" target="_blank" rel="noopener noreferrer">
          <img className="media-icon" src={Instagram} alt="Instagram"/>
          </a>
        </div>
      </div>
    </Section>
  );
};

export default media;
